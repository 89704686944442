import React, { useState } from "react"
import Layout from "../components/Layout/Layout"
import HomeLayout from "../components/HomeLayout/HomeLayout"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import FlipCard1 from "../components/CardComponent/FlipCardS"
import Flippy from "react-flippy/dist/Flippy"
import { FrontSide, BackSide } from "react-flippy/dist/FlippyCard"

const Gallery = () => {
  const [isFlipped1, setIsFlipped1] = useState(false)
  const [isFlipped2, setIsFlipped2] = useState(false)
  const [isFlipped3, setIsFlipped3] = useState(false)
  const [isFlipped4, setIsFlipped4] = useState(false)
  const data = useStaticQuery(graphql`
    query {
      Gallery: allFile(
        sort: { order: ASC, fields: name }
        filter: { sourceInstanceName: { eq: "Gallery" } }
      ) {
        nodes {
          id
          name
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      PUC: allFile(filter: { sourceInstanceName: { eq: "PUC" } }) {
        nodes {
          id
          name
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <HomeLayout>
        {data.Gallery.nodes.map((img , idx) => {
          var name = img.name.split(' ')[1]
          if(idx ==5)  
          return(<Link to="https://www.youtube.com/channel/UCHqtgHSqcSw9YP-ACr_Glwg"key={idx}>
              <Img fluid={img.childImageSharp.fluid} alt="Gallery images" />
            </Link>)
          return (
            <Link to={`/Gallery/${name}`} key={idx}>
              <Img fluid={img.childImageSharp.fluid} alt="Gallery images" />
            </Link>
          )
        })}
      </HomeLayout>
    </Layout>
  )
}

export default Gallery
